/** @jsx jsx */
import { jsx } from 'theme-ui'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { Box, Flex } from 'rebass'
import { uniqueId } from 'lodash'

import { Container, Layout, Section } from '@giraldomac/gatsby-theme-mmdbase/src/components/layout'
import { SEO } from '@giraldomac/gatsby-theme-mmdbase/src/components/elements'
import { PubHero } from '../../../components/elements'

const RatesTariffs = ({ data }) => {
  const page = data.prismic.rates__tariffs

  return (
    <Layout>
      <SEO meta_title={page.meta_title} meta_description={page.meta_description} />
      <PubHero title={RichText.asText(page.title)} />
      <Section>
        <Container>
          <Flex flexWrap="wrap" justifyContent="center">
            <Box width={['full', 4 / 5, 3 / 4]} sx={{
              backgroundColor: 'wrapperbox',
              padding: [4, 5],
            }}>

              {page.content && RichText.render(page.content)}

              {page.body.map(({ primary, fields }) => (
                <Box key={`${uniqueId('group_id_')}`}>
                  {RichText.render(primary.group)}
                  {fields.map(({ table }) => (
                    <Box key={`${uniqueId('table_id_')}`}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: RichText.asText(table),
                        }}
                      />
                    </Box>
                  ))}
                  <hr sx={{ my: 4 }} />
                </Box>

              ))}

            </Box>
          </Flex>
        </Container>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      rates__tariffs(lang: "en-us", uid: "rates-tariffs") {
        title
        content
        meta_title
        meta_description
        _linkType
        body {
          ... on PRISMIC_Rates__tariffsBodyContent_and_table {
            type
            label
            primary {
              group
            }
            fields {
              table
            }
          }
        }
      }
    }
  }
`

export default RatesTariffs
